<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block text-white" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex user-nav max-w-xs overflow-visible">
            <p class="user-name font-weight-bolder mb-0">
              {{ userName }}
            </p>
            <span class="user-status">
              {{ userCategoryName[$i18n.locale] }}
            </span>
          </div>
        </template>

        <b-dropdown-item :to="{ name: 'user-account'}" link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{$t('MyAccount')}}</span>
        </b-dropdown-item>


        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    userCategoryName() {
      if (this.$store.state.cfp.quizTypeName && this.$store.state.account.user) {
        return this.$store.state.cfp.quizTypeName.find(el=>el.type===this.$store.state.account.user.category).name
      } else {
        return ''
      }
    },
    userName() {
      if (this.$store.state.account.user) {
        if (!this.$store.state.account.user.first_name && !this.$store.state.account.user.last_name){
          return 'Utilisateur sans nom'
        } else {
          if (this.$store.state.account.user.first_name) {
            let text = this.$store.state.account.user.first_name
            text += this.$store.state.account.user.last_name ? ' ' + this.$store.state.account.user.last_name[0] + '.'
                : ''
            return text
          } else {
            return this.$store.state.account.user.last_name
          }
        }
      } else {
        return ''
      }
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      this.$store.dispatch('account/logout')
          .then(() => {
            this.$loading(false)
            this.$notify({
              time: 4000,
              color: 'primary',
              title: 'Déconnecté',
              text: 'A bientôt !'
            })
            this.$router.push({ name: 'login' })
          })
          .catch(() => {
            this.$notify({
              time: 4000,
              color: 'danger',
              title: this.$t('Error'),
              text: 'La déconnexion a échoué'
            })
      })
    },
  },
  watch: {
    '$store.state.account.user'(val) {
      this.$i18n.locale =val.language
      }
  }
}
</script>
