<template>
  <div class="d-flex justify-content-around col flex-wrap">
    <p class="clearfix mb-0">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        {{ new Date().getFullYear() }}  ©
        <b-link
          class="ml-25"
          href="https://landestini.org"
          target="_blank"
          :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-secondary' : 'text-primary'"
        >Landestini</b-link>
        <span class="d-none d-sm-inline-block"></span>
      </span>
    </p>
    <p class="clearfix mb-0">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        {{ $t('developedBy') }}
        <b-link
            class="ml-25"
            href="https://jaya-garden.fr"
            target="_blank"
            :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-secondary' : 'text-primary'"
        >Jaya</b-link>
        <span class="d-none d-sm-inline-block"></span>
      </span>
    </p>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
