export default [
  {
    header: 'platformName',
  },
  {
    title: 'Home',
    route: 'coupe-de-france-du-potager',
    icon: 'HomeIcon',
  },
  {
    title: 'Quiz',
    route: 'coupe-de-france-du-potager-evaluation',
    icon: 'ListIcon',
  },
  {
    title: 'MyAccount',
    route: 'user-account',
    icon: 'UserIcon',
  },
  {
    title: 'PreviousEditions',
    icon: 'ClockIcon',
    children : [
      {
        title: '2023',
        route: 'coupe-de-france-du-potager-evaluation-2023',
      },
      {
        title: '2022',
        route: 'coupe-de-france-du-potager-evaluation-2022',
      },
      {
        title: "2021",
        route: 'coupe-de-france-du-potager-evaluation-2021',
      },
    ]
  },
  {
    header: 'other',
  },
  {
    title: 'Mentions.title',
    icon: 'BookOpenIcon',
    children : [
      {
        title: 'Mentions.legals',
        route: 'legal-notice',
      },
      {
        title: "Mentions.cgu",
        route: 'general-conditions',
      },
      {
        title: "Mentions.data",
        route: 'gdpr-charter',
      },
      {
        title: "Mentions.cookies",
        route: 'cookie-policy',
      },
    ]
  },
]